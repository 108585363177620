<template>
  <div class="scales-settings" v-loading="loading">

    <page-heading
      heading="Шкалы"
    >
      <div slot="right">
        <div class="el-row--flex">
<!--          <library-scales-units-modal>-->
<!--            <el-button-->
<!--              slot="reference"-->
<!--              type="primary"-->
<!--              size="small"-->
<!--            >-->
<!--              Редактирование единиц измерения-->
<!--            </el-button>-->
<!--          </library-scales-units-modal>-->

          <el-button
            style="margin-left: 20px"
            type="primary"
            size="small"
            icon="fas fa-plus"
            @click="createScaleTemplate"
          >
            Добавить прогрессивную шкалу
          </el-button>
        </div>
      </div>
    </page-heading>


    <el-row :gutter="40">
      <el-col
        :span="24"
        v-for="template in scaleTemplates.design"
        :key="template.id"
      >
        <design-scale-setting-template
          :template="template"
          @update-template="updateScaleTemplate($event, 'design')"
          @delete-template="deleteScaleTemplate($event, 'design')"
          @delete-template-scope="deleteScaleTemplateScope($event, 'design')"
        ></design-scale-setting-template>
      </el-col>
    </el-row>

    <el-row :gutter="40">
      <el-col
        :span="24"
        v-for="template in scaleTemplates.progressive"
        :key="template.id"
      >
        <progressive-scale-setting-template
          :template="template"
          @update-template="updateScaleTemplate($event, 'progressive')"
          @delete-template="deleteScaleTemplate($event, 'progressive')"
          @delete-template-scope="deleteScaleTemplateScope($event, 'progressive')"
          @add-scope-to-template="addScopeToScaleTemplate($event, 'progressive')"
        ></progressive-scale-setting-template>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import requestSender from "@/api/base/requestSender";
import ClickToEdit from "@/components/ClickToEdit.vue";
import PageHeading from "@/components/PageHeading.vue";
import LibraryScalesUnitsModal from "@/components/library/LibraryScalesUnitsModal.vue";
import companyConfiguration from "../../../plugins/companyConfiguration";
import DesignScaleSettingTemplate from "@/componentsCompany/cherkizovo/scalesSettings/DesignScaleSettingTemplate.vue";
import ProgressiveScaleSettingTemplate from "@/componentsCompany/cherkizovo/scalesSettings/ProgressiveScaleSettingTemplate.vue";

export default {
  name: "scales-settings",
  computed: {
    companyConfiguration() {
      return companyConfiguration
    }
  },
  components: {
    ProgressiveScaleSettingTemplate,
    DesignScaleSettingTemplate, LibraryScalesUnitsModal, PageHeading, ClickToEdit},
  props: {},
  watch: {},
  data() {
    return {
      loading: false,
      scaleTemplates: [],
      scaleTemplatesVisibility: {},
    }
  },
  mounted() {
    this.getScaleTemplates();

    let savedVisibilitySettings = localStorage.getItem('scaleTemplatesVisibility');
    if( savedVisibilitySettings ){
      savedVisibilitySettings = JSON.parse(savedVisibilitySettings);
      this.scaleTemplatesVisibility = savedVisibilitySettings;
    }
  },
  methods: {
    getScaleTemplates(){
      this.loading = true;
      requestSender('get', 'scale/templates-list', {
        with_inactive: 1,
      })
        .then(data => {
          this.scaleTemplates = data.scales;
        })
        .finally(()=>{
          this.loading = false;
        })
    },

    createScaleTemplate(){
      this.loading = true;
      requestSender('post', 'scale/create', {
        type: 'progressive'
      })
        .finally(()=>{
          this.getScaleTemplates();
        })
    },

    updateScaleTemplate(template, type){
      this.loading = true;
      template.type = type;
      requestSender('post', 'scale/update', template)
        .then(data => {
          let templateIndex = this.scaleTemplates[type].findIndex(t => t.id===template.id);
          this.$set(this.scaleTemplates, templateIndex, data.scale);
        })
        .finally(()=>{
          this.loading = false;
        })
    },

    deleteScaleTemplate(template, type){
      this.loading = true;
      requestSender('post', 'scale/delete', {
        id: template.id,
        type: type,
      })
        .finally(()=>{
          delete this.scaleTemplatesVisibility[template.id];
          localStorage.setItem('scaleTemplatesVisibility', JSON.stringify(this.scaleTemplatesVisibility));

          this.getScaleTemplates();
        })
    },


    addScopeToScaleTemplate(template, type){
      template.scopes.push({});
      this.updateScaleTemplate(template, type);
    },
    deleteScaleTemplateScope({template, scope}, type){
      template.scopes = template.scopes.filter(s => s.id !== scope.id);
      this.updateScaleTemplate(template, type);
    },


    toggleTemplateVisibility(template){
      this.$set(this.scaleTemplatesVisibility, template.id, !this.scaleTemplatesVisibility[template.id]);
      localStorage.setItem('scaleTemplatesVisibility', JSON.stringify(this.scaleTemplatesVisibility));
    },

    changeTemplateActiveState(template){
      template.active = !template.active;
      this.updateScaleTemplate(template)
    },
  }
}
</script>


<style lang="scss" scoped>
.scales-settings-visibility{
  padding: 5px 10px 5px 0;
  cursor: pointer;
}
</style>